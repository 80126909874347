<template>
  <v-edit-dialog
    :return-value.sync="item"
    v-if="!statusAprovado(item) && (datasValidasEdicao(item) || item.periodicidade === 'MENSAL')"
    persistent
    :ref="`${prefixRefNameTipoUso}${item.idApuracao}`"
    @open="guardarValorInicial(item)"
  >
    <div class="mt-2 text-sm-left">
      {{ item.tipoUsoNovo ? item.tipoUsoNovo : item.tipoUso }}
      <v-icon size="19">edit</v-icon>
    </div>
    <template v-slot:input>
      <div class="mt-4 text-h6">
        {{ $t('title.editar')}}
      </div>
      <v-form lazy-validation ref="form">
        <div class="mt-4">
          <v-autocomplete
            id="tipo-uso"
            class="custom-autocomplete"
            v-model="item.idTipoUsoNovo"
            :items="listaTiposUso"
            :label="$tc('label.tipo_uso')"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nome"
            item-value="id"
            :clearable="true"
            :rules="[rules.required]"
            v-if="!statusAprovado(item)"
          ></v-autocomplete>
          <v-text-field
            :label="$tc('label.justificativa')"
            v-model="item.justificativaTipoUso"
            type="text"
            :rules="[rules.required]"
          >
          </v-text-field>
        </div>
      </v-form>
      <div class="ma-3">
        <v-btn @click="cancelar(item)" text color="grey"> {{ $t('label.cancelar') }} </v-btn>
        <v-btn @click="salvarTipoUsoIndividual(item)" :disabled="desabilitaSalvar(item)" text color="primary"> {{ $t('label.salvar') }} </v-btn>
      </div>
    </template>
    <span v-if="item.tipoUsoNovo" class="grey--text caption">
      {{ item.tipoUso }}
    </span>
  </v-edit-dialog>
  <v-edit-dialog
    :return-value.sync="item"
    v-else-if="statusAprovado(item) && item.tipoUsoNovo"
    :ref="`${prefixRefNameTipoUso}${item.idApuracao}`"
  >
    <div class="mt-2">
      {{ item.tipoUsoNovo }}
      <v-icon class="ml-2">description</v-icon>
    </div>
    <template v-slot:input>
        <div class="mt-4">
          <v-text-field
            :label="$tc('label.tipo_uso')"
            v-model="item.tipoUso"
            type="text"
            disabled
          />
          <v-text-field
            :label="$tc('label.justificativa')"
            v-model="item.justificativaTipoUso"
            type="text"
            disabled
            :rules="[rules.required]"
          >
          </v-text-field>
        </div>
    </template>
  </v-edit-dialog>
  <td v-else-if="!statusAprovado(item) && !datasValidasEdicao(item)">
    <tr>
      <td>
        {{ item.tipoUsoNovo ? item.tipoUsoNovo : item.tipoUso }}
      </td>
    </tr>
    <tr>
      <td class="grey--text caption">
        <span v-if="item.tipoUsoNovo" class="grey--text caption">
          {{ item.tipoUso }}
        </span>
      </td>
    </tr>
  </td>
  <td v-else>
    {{ item.tipoUso }}
  </td>
</template>

<script>

import { getMoney } from '../../../common/functions/helpers';

export default {
  name: 'DialogEditTipoUso',
  components: {
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
    salvarTipoUsoIndividual: {
      type: Function,
      default: () => ({}),
    },
    listaTiposUso: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      prefixRefNameTipoUso: 'modalTipoUso-',
      rules: {
        required: (value) => (!!value || value === 0) || this.$t('message.campo_obrigatorio'),
      },
      idTipoUso: null,
      justificativa: '',
    };
  },
  methods: {
    getMoney,
    statusAprovado(item) {
      return item.statusApuracao === 'APROVADO';
    },
    desabilitaSalvar(item) {
      return !item.idTipoUsoNovo || !item.justificativaTipoUso;
    },
    formatarValor(valor) {
      if (valor === 0) {
        return '-';
      }
      return this.getMoney(valor) || '-';
    },
    datasValidasEdicao(item) {
      return item.dataParcialFormatada && item.dataParcialFormatada.resultado;
    },
    cancelar(item) {
      item.idTipoUsoNovo = this.idTipoUso;
      item.justificativaTipoUso = this.justificativa;
      const modalRef = this.prefixRefNameTipoUso.concat(item.idApuracao);
      this.$refs[modalRef].isActive = false;
    },
    guardarValorInicial(item) {
      this.idTipoUso = item.idTipoUsoNovo;
      this.justificativa = item.justificativaTipoUso;
    },
  },
};
</script>
