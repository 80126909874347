<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="nudgeWidth"
    transition="slide-x-transition"
    :offset-x="offsetX"
    :offset-y="offsetY"
    nudge-right="40"
    nudge-top="15">

    <template v-slot:activator="{ on }">
      <span v-on="on">
        <slot name="ativadorPopover">
          <v-btn icon :color="corIcone">
            <v-icon>{{ icone }}</v-icon>
          </v-btn>
        </slot>
      </span>
    </template>

    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12">
            <strong>{{ `${$t(label)}` }}: </strong>
            <span v-if="isValorMonetario">{{ getMoney(this.valor) }}</span>
            <span v-else-if="isValorPercentual">{{ getPercent(this.valor) }}</span>
            <span v-else>{{ this.texto }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import { getMoney, getPercent } from '../common/functions/helpers';

export default {
  data: () => ({
    menu: false,
  }),
  props: {
    corIcone: {
      type: String,
      default: null,
    },
    offsetX: true,
    offsetY: false,
    nudgeWidth: {
      type: Number,
      default: 200,
    },
    icone: {
      type: String,
      default: 'info',
    },
    label: {
      type: String,
      default: '',
    },
    isValorMonetario: {
      type: Boolean,
      default: false,
    },
    isValorPercentual: {
      type: Boolean,
      default: false,
    },
    valor: {
      type: Number,
      default: 0,
    },
    texto: {
      type: String,
      default: '',
    },
  },
  methods: {
    getPercent,
    getMoney,
  },
};
</script>
