<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-form lazy-validation ref="formJustificativa">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('label.informe_justificativa') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  name="justificativa_textarea"
                  required
                  :rows="1"
                  auto-grow
                  autofocus
                  maxlength="150"
                  v-model.trim="justificativa"
                  :rules="[rules.required]">
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text
            color="secundary"
            @click="closeModalJustificativa">{{ $t('label.cancelar') }}
          </v-btn>
          <v-btn text
            color="primary"
            @click="confirmarCancelamento()">{{ $t('label.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

export default {
  name: 'DialogApuracaoContratoCancelamento',
  props: {
    confirmarCancelarApuracao: Function,
  },
  data() {
    return {
      dialog: false,
      justificativa: '',
      apuracao: {},
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    open(item) {
      this.dialog = true;
      this.justificativa = '';
      this.apuracao = item;
    },
    close() {
      this.dialog = false;
    },
    confirmarCancelamento() {
      if (!this.$refs.formJustificativa.validate()) {
        return;
      }
      this.confirmarCancelarApuracao(this.apuracao, this.justificativa);
      this.dialog = false;
    },
    closeModalJustificativa() {
      this.dialog = false;
    },
  },
};
</script>
