<template>
  <v-form ref="form">
    <filtro-rapido
       v-model="abrirFiltro"
       ref="filtrosRapidos"
       id="ApuracaoFiltros__filtro_rapido"
       v-if="carregouMetadados && carregouFiltrosPadroes"
       :campos-formulario="camposFormulario"
       :metadados="metadadosAgrupado"
       :ordenacao-campos="ordenacaoCampos"
       :mostrar-fixar-filtros="false"
       :formulario-filtros="false"
       :ignora-obrigatorios="false"
       :grupo-expandido="`${$tc('title.contrato', 1)}`"
       :setar-filtro-padrao-ao-limpar="true"
       :aplicar-filtro-no-carregamento="false"
       :filtro-padrao="filtroPadrao"
       :desabilita-aplicar="desabilitaAplicar"
       @FiltroRapido__AplicaFiltros="aplicarFiltros"
       @FiltroRapido_conteinerCarregado="$emit('ApuracaoContratoFiltros_conteinerCarregado')"
       @FiltroRapido__HabilitaIconBadge="(value) => $emit('FiltroRapido__HabilitaIconBadge', value)">
    </filtro-rapido>

  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FiltroRapido from '../../shared-components/filtro-rapido/FiltroRapido';

export default {
  name: 'ApuracaoContratoFiltros',
  components: { FiltroRapido },
  props: {
    value: false,
    desabilitaAplicar: {
      type: Boolean,
      default: true,
    },
    filtrosComplementares: Object,
  },
  data() {
    return {
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      metadadoResource: this.$api.metadado(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      apuracaoContratoResource: this.$api.apuracaoContrato(this.$resource),
      tipoContratoResource: this.$api.tipoContrato(this.$resource),

      abrirFiltro: false,
      carregouFiltrosPadroes: false,
      status: null,
      isPaginacaoAlterada: false,
      ordenacaoCampos: [
        {
          campo: 'grupo_fornecedor',
          nomeGrupo: 'Ação',
        },
        {
          campo: 'ocultar_apuracao_sem_nota',
          nomeGrupo: 'Ação',
          forcarGrupo: true,
        },
        {
          campo: 'ocultar_apuracoes_negativas',
          nomeGrupo: 'Ação',
          forcarGrupo: true,
        },
        {
          campo: 'ocultar_apuracoes_com_valor_zero',
          nomeGrupo: 'Ação',
          forcarGrupo: true,
        },
        {
          campo: 'apresentar_apuracoes_finalizadas',
          nomeGrupo: 'Ação',
          forcarGrupo: true,
        },
      ],
      filtroPadrao: {},
      menuProps: {
        closeOnClick: false,
        closeOnContentClick: false,
        openOnClick: false,
        maxHeight: 300,
        nudgeLeft: 150,
      },
      menuPropsExtensao: {
        nudgeLeft: 100,
      },
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getContratoMetadado',
    ]),
    camposFormulario() {
      return {
        padrao: [
          {
            labelCampo: 'grupo_fornecedor',
            nomCampoId: 'grupo_fornecedor',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: () => this.planejamentoAcaoResource
                .buscarGruposFornecedores({ autocomplete: 'SAMSUNG' }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
            menuProps: this.menuProps,
          },
          {
            labelCampo: 'ocultar_apuracao_sem_nota',
            tipoCampo: 'BOOLEAN',
          },
          // {
          //   labelCampo: 'ocultar_apuracoes_negativas',
          //   tipoCampo: 'BOOLEAN',
          // },
          {
            labelCampo: 'ocultar_apuracoes_com_valor_zero',
            tipoCampo: 'BOOLEAN',
          },
          {
            labelCampo: 'apresentar_apuracoes_finalizadas',
            tipoCampo: 'BOOLEAN',
          },
        ],
      };
    },
    carregouMetadados() {
      return this.getAcaoMetadado && this.getContratoMetadado;
    },
    metadadosAgrupado() {
      if (!this.carregouMetadados) {
        return {};
      }

      const metadados = { ...this.getContratoMetadado };
      metadados.dicionario = { ...this.getAcaoMetadado.dicionario };
      Object.assign(metadados.dicionario, this.getContratoMetadado.dicionario);

      metadados.mapaCamposDinamicos = { ...this.getAcaoMetadado.mapaCamposDinamicos };
      Object.assign(metadados.mapaCamposDinamicos, this.getContratoMetadado.mapaCamposDinamicos);

      metadados.mapaEntidades = {};
      Object.assign(metadados.mapaEntidades, this.getContratoMetadado.mapaEntidades);

      Object.keys(this.getAcaoMetadado.mapaEntidades).forEach((k) => {
        metadados.mapaEntidades[k] = { ...this.getAcaoMetadado.mapaEntidades[k] };
        metadados.mapaEntidades[k].depObrigatoria = false;
        metadados.mapaEntidades[k].manyToMany = false;
        metadados.mapaEntidades[k].menuProps = this.menuPropsExtensao;
      });

      Object.keys(this.getContratoMetadado.mapaEntidades).forEach((k) => {
        metadados.mapaEntidades[k] = { ...this.getContratoMetadado.mapaEntidades[k] };
        metadados.mapaEntidades[k].depObrigatoria = false;
        metadados.mapaEntidades[k].manyToMany = false;
        metadados.mapaEntidades[k].menuProps = this.menuPropsExtensao;
      });

      if (metadados.cabecalho) {
        metadados.cabecalho.concat(...this.getAcaoMetadado.cabecalho);
      } else {
        metadados.cabecalho = this.getAcaoMetadado.cabecalho;
      }
      return metadados;
    },
  },
  watch: {
    value(val) {
      this.abrirFiltro = val;
    },
    abrirFiltro(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
      'setContratoMetadado',
    ]),
    aplicarFiltros(filtrosAplicados) {
      if (!this.$refs.form.validate() || !filtrosAplicados) {
        return;
      }
      const mudouFiltrosLaterais = JSON.stringify(this.filtrosComplementares) === JSON.stringify(filtrosAplicados);
      let isBuscaFiltroLateral = true;
      if (this.isPaginacaoAlterada) {
        isBuscaFiltroLateral = false;
      }
      filtrosAplicados.id_grupo_fornecedor = filtrosAplicados.grupo_fornecedor;
      this.$emit('ApuracaoContratoFiltros__AplicaFiltros', filtrosAplicados, mudouFiltrosLaterais, isBuscaFiltroLateral);
      this.isPaginacaoAlterada = false;
    },
    buscarFornecedorContrato() {
      if (!this.$route.params.id_grupo_fornecedor) {
        return new Promise((res) => { res(); });
      }
      const { idContrato } = { ...this.$route.params };
      return this.planejamentoContratoResource.buscarGrupoFornecedorContrato({ idContrato })
        .then((response) => {
          this.filtroPadrao.grupo_fornecedor = response.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    preencherFiltroPadrao() {
      Promise
        .all([
        ])
        .then(() => {
          this.setarFiltroOcultarApuracaoSemNotaValorPadrao();
          this.setarFiltroOcultarApuracoesComValorZero();
          this.setarFiltroApresentarApuracoesFinalizadas();
          this.setarFiltroTipoContratoSamsung();
          this.carregouFiltrosPadroes = true;
        });
    },
    preencherFiltroBreadCrumbs() {
      Promise
        .all([
          this.buscarFornecedorContrato(),
        ])
        .then(() => {
          this.preencherFiltroPadrao();
        });
    },
    setarFiltroOcultarApuracaoSemNotaValorPadrao() {
      this.filtroPadrao.ocultar_apuracao_sem_nota = true;
    },
    setarFiltroOcultarApuracoesNegativas() {
      this.filtroPadrao.ocultar_apuracoes_negativas = true;
    },
    setarFiltroOcultarApuracoesComValorZero() {
      this.filtroPadrao.ocultar_apuracoes_com_valor_zero = true;
    },
    setarFiltroApresentarApuracoesFinalizadas() {
      this.filtroPadrao.apresentar_apuracoes_finalizadas = true;
    },
    setarFiltroTipoContratoSamsung() {
      return this.tipoContratoResource.buscarTipoContratoSamsung()
        .then((response) => {
          this.filtroPadrao.id_contrato_tipo = response.data;
          this.$emit('ApuracaoContratoFiltros__setaIdContratoTipo', this.filtroPadrao.id_contrato_tipo);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    setarIsPaginacaoAlterada(valor) {
      this.isPaginacaoAlterada = valor;
    },
  },
  beforeMount() {
    if (!this.$route.params.id_acao_tipo) {
      this.preencherFiltroPadrao();
    } else {
      this.preencherFiltroBreadCrumbs();
    }
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
    this.setContratoMetadado({
      resource: this.metadadoResource.definicaoContrato,
    });
  },
};
</script>

<style scoped>

</style>
