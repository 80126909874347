<template>
  <v-dialog v-model="dialog"
      id="dialog_acao_categoria_valor_intervalo_cumulativa"
      transition="slide-x-transition"
      overlay-opacity="0"
      persistent
      width="70%">
    <v-card style="min-height:168px; height: 100%;">
      <v-card-title style="word-break: break-word; width: 97%;">
        {{ $tc('label.intervalo_meta', 1) }} {{ subtitulo }}
      </v-card-title>
      <span  v-show="msgParciais !== null" class="orange--text ml-6">
        {{ msgParciais }}
      </span>
      <v-card-subtitle class="font-weight-bold mt-2" style="font-size: 16px;">
        {{ `${$tc('label.verba_total_atingida')}:` }} {{ convertToPercent(verbaTotal) }}
      </v-card-subtitle>
      <v-card-text>
        <v-container fluid px-0 py-0>
          <v-row>
            <v-col cols="12" >
              <v-data-table
                :headers="headers"
                :items="metas"
                hide-default-footer
                disable-pagination
                :key="tableKey"
                :items-per-page="-1"
                :no-data-text="$t('message.nenhum_intervalo')"
                style="overflow:hidden">
                <template v-slot:item="{ item }">
                  <tr>
                    <td v-if="verificaSePossuiMetaPartida()">
                      <v-checkbox
                        v-model="item.indMetaDePartida"
                        primary
                        :disabled="true"
                        hide-details
                        class="mx-5 mt-0"
                      ></v-checkbox>
                    </td>
                    <td class="text-center">
                      <span v-text="`${$t(`configuracao.CALCULO_APURACAO.${item.calculoApuracao}`)}`"></span>
                    </td>
                    <td class="text-center">
                      <span v-text="formatarMeta(item.calculoApuracao, item.vlrMetaDe)"></span>
                    </td>
                    <td class="text-center">
                      <span v-if="item.vlrMetaAte" v-text="formatarMeta(item.calculoApuracao, item.vlrMetaAte)"></span>
                    </td>
                    <td class="text-center">
                      <span v-text="formatarBonificacao(item.vlrBonificacao)"></span>
                    </td>
                    <td  class="text-center">
                      <v-btn
                        icon
                        class="mx-2"
                        @click.stop="abrirModalSelecaoProduto(item)"
                      >
                        <v-tooltip top v-if="item.idsProdutos && item.idsProdutos.length">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">category</v-icon>
                          </template>
                          <span
                          v-text="$t('message.quantidade_produtos_selecionados', {text: item.idsProdutos.length})">
                          </span>
                        </v-tooltip>
                        <v-tooltip top v-else>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                            add_box
                            </v-icon>
                          </template>
                          <span v-text="$t('message.todos_produtos')"></span>
                        </v-tooltip>
                      </v-btn>
                    </td>
                    <td class="text-center">
                      <span v-text="formatarRealizado(item)"></span>
                    </td>
                    <td v-if="true">
                      <v-btn icon
                        v-if="verificaSePossuiNotasExportacao(item)"
                        class="mx-3"
                        @click="exportarNotas(item)">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                              get_app
                            </v-icon>
                          </template>
                          <span v-text="$tc('label.exportar_nota', 2)"></span>
                        </v-tooltip>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="close">{{ $t('label.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
    <planejamento-contrato-modal-selecao-produto-meta
        ref="modalSelecaoProdutoMeta"
        :meta="meta"
        :edicao="false"
        :configuracao="{}"
        :somente-leitura="true"
        :categoria-selecionada="categoria"
        :origem="dadosOrigem"
      />
  </v-dialog>
</template>
<script>
import moment from 'moment';
import { getMoney, getPercent, copyObject } from '../../../common/functions/helpers';
import {
  convertToMoney,
  convertToPercent,
  convertToNumberFormat,
} from '../../../common/functions/numeric';
import {
  formatlastDayOfTheMonth,
} from '../../../common/functions/date-utils';
import PlanejamentoContratoModalSelecaoProdutoMeta from '../../planejamento-contrato/form/passo-acoes-carta-parceria/PlanejamentoContratoModalSelecaoProdutoMeta';
import exportacao from '../../../common/functions/exportacao';

export default {
  data() {
    return {
      resource: this.$api.apuracaoAcaoListagem(this.$resource),
      dialog: false,
      tableKey: 1,
      headers: [],
      metas: [],
      dadosOrigem: {},
      idsCategoria: '',
      idGrupoFornecedor: '',
      meta: {},
      categoria: {},
      verbaTotal: 0,
      idApuracao: null,
      idAcao: null,
      idRecebimentoParcial: null,
      datasValidas: [],
      filtros: {},
      msgParciais: null,
      qtdParciais: 0,
    };
  },
  props: {
    subtitulo: {
      type: String,
      default: '',
    },
  },
  components: {
    PlanejamentoContratoModalSelecaoProdutoMeta,
  },
  methods: {
    copyObject,
    getPercent,
    getMoney,
    convertToMoney,
    convertToPercent,
    convertToNumberFormat,
    open(idAcao, idApuracao, idGrupoFornecedor, idsCategoriasAgrupadas, idRecebimentoParcial, datasValidas, filtros) {
      this.idsCategoria = idsCategoriasAgrupadas;
      this.idGrupoFornecedor = idGrupoFornecedor;
      this.idApuracao = idApuracao;
      this.idAcao = idAcao;
      this.datasValidas = datasValidas;
      this.idRecebimentoParcial = idRecebimentoParcial;
      this.filtros = filtros;
      this.montarHeader();
      this.buscarMetas(idApuracao, idRecebimentoParcial);
    },
    close() {
      this.dialog = false;
      this.headers = [];
    },
    abrirModalSelecaoProduto(meta) {
      this.meta = copyObject(meta);
      this.montaObjetoContrato();
      setTimeout(() => {
        this.$refs.modalSelecaoProdutoMeta.open();
      }, 350);
    },
    montaObjetoContrato() {
      this.dadosOrigem = {
        idFornecedor: null,
        idGrupoFornecedor: this.idGrupoFornecedor,
      };
      this.categoria.idsCategoriasAgrupadas = this.idsCategoria;
    },
    formatarMeta(calculoApuracao, valor) {
      if (calculoApuracao) {
        if (calculoApuracao === 'SELLIN_VOLUME' || calculoApuracao === 'SELLOUT_VOLUME') {
          return convertToNumberFormat(valor);
        }
        if (calculoApuracao === 'SELLIN_PERCENTUAL' || calculoApuracao === 'SELLOUT_PERCENTUAL') {
          return convertToPercent(valor);
        }
        return convertToMoney(valor);
      }
      return convertToNumberFormat(valor, {
        precision: 0,
      });
    },
    buscarMetas(idApuracao, idRecebimentoParcial) {
      let {
        dataInicio, dataFim,
      } = this.filtros;
      dataInicio = moment(dataInicio, 'YYYY-MM').format('YYYY-MM-DD');
      dataFim = formatlastDayOfTheMonth(moment(dataFim, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
      this.resource.buscarMetasProdutos({
        idApuracao, idRecebimentoParcial, dataInicio, dataFim,
      }).then((res) => {
        this.metas = this.ordernarMetas(res.data);
        this.metas.sort((a, b) => a.indMetaDePartida > b.indMetaDePartida);
        const isMetaPartida = this.verificaSePossuiMetaPartida();

        if (this.metas && this.metas.length) {
          this.qtdParciais = this.metas[0].qtdParciais;
        }
        this.verificaRetornaMgsParcial();

        this.metas.forEach((meta) => {
          if (meta.idsProdutos) {
            meta.idsProdutos = meta.idsProdutos.split(',').map((id) => parseInt(id, 10));
          }
        });

        this.verbaTotal = this.metas.map((m) => {
          if (m.vlrRealizado >= m.vlrMetaDe
              && (m.vlrMetaAte === 0 || ((m.vlrMetaAte !== 0 && m.vlrRealizado !== 0) && m.vlrRealizado <= m.vlrMetaAte))) {
            return m.vlrBonificacao;
          }
          return 0;
        })
          .reduce((a, b) => a + b);

        if (isMetaPartida) {
          this.headers.unshift(
            {
              text: this.$t('label.meta_de_partida'), value: '', sortable: false, align: 'left',
            },
          );
        }
        this.dialog = true;
      });
    },
    ordernarMetas(metas) {
      if (metas.length) {
        return metas.sort((a, b) => {
          const elemtA = a.indMetaDePartida;
          const elemetB = b.indMetaDePartida;
          if (elemtA && !elemetB) {
            return -1;
          }
          if (!elemtA && elemetB) {
            return 1;
          }

          return 0;
        });
      }
      return [];
    },
    exportarNotas(item) {
      const params = {
        id_acao: Number(item.idAcao),
        id_apuracao: Number(this.idApuracao),
        id_meta_intervalo: Number(item.id),
        id_recebimento_parcial: this.idRecebimentoParcial,
      };
      if (item.calculoApuracao === 'SELLOUT_VOLUME') {
        exportacao.exportar(null, 'notas_sellout_apuracao_meta_cumulativa', this, params);
      } else {
        exportacao.exportar(null, 'notas_sellin_apuracao_meta_cumulativa', this, params);
      }
    },
    formatarRealizado(item) {
      if (this.verbaTotal === 0 && this.verificaSePossuiMetaPartida() && item.vlrRealizado === 0) {
        return '-';
      }
      return item.calculoApuracao === 'SELLIN_VALOR' ? convertToMoney(item.vlrRealizado) : convertToNumberFormat(item.vlrRealizado);
    },
    formatarBonificacao(valor) {
      return convertToPercent(valor);
    },
    verificaSePossuiNotasExportacao(item) {
      return item.vlrRealizado > 0;
    },
    verificaSePossuiMetaPartida() {
      return !!this.metas.find((meta) => meta.indMetaDePartida === true);
    },
    verificaRetornaMgsParcial() {
      if (this.qtdParciais >= 2 && this.datasValidas.length === 0) {
        this.msgParciais = this.$tc('message.recebimentos_parciais_ja_gerados');
      } else {
        this.msgParciais = null;
      }
    },
    montarHeader() {
      this.headers = [
        {
          text: this.$t('label.calculo_apuracao'), value: 'calculo_apuracao', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.meta_de'), value: 'vlrMetaDe', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.meta_ate'), value: 'vlrMetaAte', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.verba'), value: 'vlrBonificacao', sortable: false, align: 'center',
        },
        {
          text: this.$tc('label.produto', 2), value: 'produto', sortable: false, align: 'center',
        },
        {
          text: this.$tc('label.realizado', 2), value: 'vlrRealizado', sortable: false, align: 'center',
        },
        {
          text: '', value: '', sortable: false, align: 'center',
        },
      ];
    },
  },
};
</script>
