<template>
  <v-dialog v-model="dialog" :persistent="true" max-width="40%">
      <v-card>
        <v-card-title>
          <span class="headline">{{$t('label.periodo_calculado') }}</span>
        </v-card-title>
        <v-card-text >
          {{ this.data }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text
                 @click="close()">{{$t('label.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ModalVisualizarPeriodoCalculado',
  props: {
  },
  data() {
    return {
      dialog: false,
      data: '',
    };
  },
  methods: {
    open(apuracao) {
      this.data = apuracao.dataParcialFormatada.resultado;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    cancelar() {
      this.close();
      this.$emit('cancelar');
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
