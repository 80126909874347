export default {
  data() {
    return {
      exibirFiltro: true,
      breadcrumbs: [
        {
          text: this.$tc('title.contrato_fechamento_lote', 1),
          to: 'apuracao-contrato-samsung',
          disabled: true,
          nivel: 1,
        },
      ],
    };
  },
  methods: {
  },
};
