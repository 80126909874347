<template>
  <div>
    <v-container pt-6 fluid v-resize="onResize">
      <v-row>
        <v-data-table
          :key="panelKey"
          item-key="idApuracao"
          :headers="headersApuracao"
          :items="apuracoes"
          :options.sync="pagination"
          :server-items-length="totalPage"
          :expanded.sync="expanded"
          class="elevation-0"
          :no-data-text="$t('label.tabela_sem_conteudo')"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50],
          }">
          <template v-slot:[`item.checkbox`]="{ item }">
            <v-checkbox
              v-model="item.selecionado"
              v-if="!statusAprovado(item)"
              hide-details
              class="shrink pt-0 mt-0"
              @click.stop="(e) => selecionarItem(item, e)">
            </v-checkbox>
          </template>
          <template v-slot:[`item.icon`]="{ item }">
            <v-tooltip bottom v-if="!statusAprovado(item)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="orange darken-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  report_problem
                </v-icon>
              </template>
              <span>{{ $t('message.periodo_total_ou_parte_apurado') }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="statusAprovado(item)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="deep-orange accent-4"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>{{ $t('label.periodo_apurado') }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.acoes`]="{ item }">
            <v-menu bottom
              origin="center center"
              transition="scale-transition">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :id="'more_vert_' + item.id"
                  icon>
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="detalharApuracao(item)">
                  <v-list-item-action>
                    <v-icon>details</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="!statusAprovado(item) && !item.vlrNdGerado"
                  @click="abrirModalCancelamento(item)">
                  <v-list-item-action>
                    <v-icon>cancel</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{ $tc('label.cancelar', 1) }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="!indCalculoSelloutVolume(item) || (indCalculoSelloutVolume(item) && !indBonificacaoFixo(item))"
                  @click="exportarNotas(item, false)">
                  <v-list-item-action>
                    <v-icon>get_app</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{ $tc('label.exportar_nota', 2) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`item.periodoVigencia`]="{ item }">
            <div>
            <span v-if="!statusAprovado(item) && item.dataParcialFormatada && item.dataParcialFormatada.resultado
              && !item.dataParcialFormatada.abrirModal">
              {{ item.dataParcialFormatada.resultado }}
            </span>
            <v-btn v-if="!statusAprovado(item) && item.dataParcialFormatada && item.dataParcialFormatada.resultado
              && item.dataParcialFormatada.abrirModal" icon class="mx-0"
              @click.native="visualizarPeriodoCalculado(item)">
              <v-icon>visibility</v-icon>
            </v-btn>
            </div>
          </template>
          <template v-slot:item.calculoBonificacao="{ item }">
            {{ item.calculoBonificacao ? $tc(`configuracao.CALCULO_BONIFICACAO.${item.calculoBonificacao}`) : '-' }}
          </template>
          <template v-slot:item.calculoApuracao="{ item }">
            {{ item.calculoApuracao ? $tc(`configuracao.METAS_BENEFICIOS.${item.calculoApuracao}`) : '-' }}
          </template>
          <template v-slot:[`item.qtdProduto`]="{ item }">
            <v-btn
              icon
              large
              @click="visualizarListaProdutos(item)"
              >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">visibility</v-icon>
                </template>
                <span v-if="item.qtdProduto > 0">
                  {{ item.qtdProduto }} {{ item.qtdProduto === 1 ? $tc('label.sku_selecionado', 1) : $tc('label.sku_selecionado', 2) }}
                </span>
                <span v-else> {{ $t('label.todos') }} </span>
              </v-tooltip>
            </v-btn>
          </template>
          <template v-slot:[`item.verba`]="{ item }">
            <tr v-if="item.indMetaIntervalo">
              <td>
                <span class="d-block">
                  {{ item.faixaAtingida ? item.formaBonificacao === 'FIXO' ?
                    getMoney(item.faixaAtingida) : getPercent(item.faixaAtingida) : $tc('label.sem_atingimento', 1)}}
                </span>
              </td>
              <td>
                <v-btn
                  icon
                  @click="isMetaCumulativa(item.tipoMetaApuracao) ? abrirModalVerbaIntervaloMetaCumulativa(item) : abrirModalIntervaloMeta(item)"
                  class="mx-2 text-left"
                >
                  <v-icon>
                    add_box
                  </v-icon>
                </v-btn>
              </td>
            </tr>
            <span v-else>
              {{ formatarVerba(item) }}
            </span>
          </template>
          <template v-slot:[`item.vlrNdGerado`]="{ item }">
            <span v-if="item.vlrNdGerado">
              {{ getMoney(item.vlrNdGerado) }}
              <v-btn
                icon
                @click="buscarNdsGeradas(item.idApuracao)"
                class="mx-2"
              >
                <v-icon>
                  info
                </v-icon>
              </v-btn>
            </span>
            <span v-else>
              {{ getMoney(item.vlrNdGerado) }}
            </span>
          </template>
          <template v-slot:[`item.vlrRealizado`]="{ item }">
            <v-container fluid>
              <v-row>
                <v-col cols="12" v-if="indEdicaoRealizado(item) && realizadoPorQuantidade(item)" :sm="indRealizadoDetalhamento(item) ? 8 : 12">
                  <v-text-field
                    v-if="realizadoPorQuantidade(item)"
                    :id="`vlrRealizado-${item.idApuracao}`"
                    :ref="`vlrRealizado-${item.idApuracao}`"
                    v-model="item.vlrRealizado"
                    :rules="[rules.required, rules.valorAjusteNegativo]"
                    :disabled="statusAprovado(item)"
                    type="Number"
                    style="min-width:80px"
                    :hint="String(item.vlrRealizadoOriginal)"
                    @focus="guardarValorInicial(item)"
                    @change="alteracaoValorRealizado(item)"
                  ></v-text-field>
                </v-col>
                <v-col v-else cols="12" :sm="8" class="mt-2">
                  <span v-if="item.indMetaIntervalo">
                    {{ item.vlrRealizado ? formatarValorRealizado(item.vlrRealizado, item.calculoApuracao, item.tipoMetaApuracao) : '-'}}
                    <v-btn
                      v-if="isMetaCumulativa(item.tipoMetaApuracao)"
                      icon
                      @click="abrirModalIntervaloMetaCumulativa(item)"
                      class="mx-2"
                    >
                      <v-icon>
                        info
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-else-if="indCalculoSelloutVolume(item) && item.vlrRealizado"
                      icon
                      @click="verificarQuantidadeRegristrosExportacao(item)"
                      class="mx-2"
                    >
                      <v-icon>
                        get_app
                      </v-icon>
                    </v-btn>
                  </span>
                  <span v-else class="grey--text caption">
                    <br/>
                    {{'-'}}
                  </span>
                </v-col>
                <v-col cols="12" sm="4" v-if="indRealizadoDetalhamento(item)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click="abrirModalRealizado(item.idApuracao)"
                        :class="indEdicaoRealizado(item) ? 'mx-2 mt-4' : ''"
                      >
                        <v-icon>
                          info
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $tc('label.detalhamento_realizado', 1) }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.valorBase`]="{ item }">
            <dialog-edit-valor-base
              v-if="item.formaBonificacao !== 'FIXO'"
              :id="`valorBaseDefinido-${item.idApuracao}`"
              :ref="`valorBaseDefinido-${item.idApuracao}`"
              :item="item"
              :salvar-valor-alterado="salvarValorAlterado"
              :ind-valor-base-definido-alterado="indValorBaseDefinidoAlterado"
              @DialogEditValorBase__isEditandoValorBase="alterarIsEditandoValorBase"
            >
            </dialog-edit-valor-base>
            <span v-else> {{ $t('-') }} </span>
          </template>
          <template v-slot:[`item.vlrRetroativo`]="{ item }">
            {{ getMoney(item.vlrRetroativo) }}
            <tooltip-ajuda v-if="item.vlrRetroativo" color="black"
              :mensagem="`${$t('message.valor_retroatividade', { descricaoContratoAnterior: item.descricaoContratoAnterior})}`"
          />
          </template>
          <template v-slot:[`item.recebimentoDefinido`]="{ item }">
            <dialog-edit-recebimento-definido
              :id="`recDefinido-${item.idApuracao}`"
              :ref="`recDefinido-${item.idApuracao}`"
              :item="item"
              :salvar-valor-alterado="salvarValorAlterado"
              :ind-recebimento-definido-alterado="indRecebimentoDefinidoAlterado"
              :somente-leitura="isEditandoValorBase"
            >
            </dialog-edit-recebimento-definido>
          </template>
          <template v-slot:[`item.tipoUso`]="{ item }">
            <dialog-edit-tipo-uso
              :id="`tipoUso-${item.idApuracao}`"
              :ref="`tipoUso-${item.idApuracao}`"
              :item="item"
              :lista-tipos-uso="listaTiposUso"
              :salvar-tipo-uso-individual="salvarTipoUsoIndividual"
            >
            </dialog-edit-tipo-uso>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <apuracao-contrato-tabela-fornecedor
                :fornecedores="item.fornecedores"
                :dta-inicio="filtros.dataInicio"
                :dta-fim="filtros.dataFim">
              </apuracao-contrato-tabela-fornecedor>
            </td>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
    <dialog-justificativa
      ref="dialogJustificativa"
      :justificativa="apuracaoSelecionada.justificativaAlterarRecebimento"
      @confirmar="confirmarAlteracaoValor"
      @cancelar="cancelarAlteracaoValor">
    </dialog-justificativa>

    <dialog-justificativa
      ref="dialogJustificativaValorBase"
      :justificativa="apuracaoSelecionada.justificativaAlterarValorBase"
      @confirmar="confirmarAlteracaoValorBase"
      @cancelar="cancelarAlteracaoValorBase">
    </dialog-justificativa>

    <dialog-realizado-detalhamento
      ref="dialogRealizadoDetalhamento">
    </dialog-realizado-detalhamento>

    <dialog-apuracao-contrato-meta-intervalo
      ref="dialogMetaIntervalo">
    </dialog-apuracao-contrato-meta-intervalo>

    <dialog-apuracao-contrato-cancelamento
      ref="dialogCancelamento"
      :confirmarCancelarApuracao="confirmarCancelarApuracao">
    </dialog-apuracao-contrato-cancelamento>

    <dialog-apuracao-contrato-nds-geradas
      ref="dialogNDsGeradas">
    </dialog-apuracao-contrato-nds-geradas>

    <modal-visualizar-periodo-calculado
    ref="ModalVisualizarPeriodoCalculado"
    :verificaRetornaDatasValidas="verificaRetornaDatasValidas"
    >
    </modal-visualizar-periodo-calculado>

    <v-dialog
      v-model="dialogPlanejamentoContrato"
      v-if="dialogPlanejamentoContrato"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition">
      <v-card class="ModalPlanejamentoContrato">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close()">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title><b>{{ $tc('title.planejamento_contrato', 1).toUpperCase()}}</b></v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <planejamento-contrato-form
            ref="modalForm"
            :somente-leitura="true"
            :id-contrato-fechamento="contrato.idContrato">
          </planejamento-contrato-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <planejamento-contrato-modal-selecao-produto
      ref="modalSelecaoProduto"
      :somente-leitura="true"
      :acao="acao"
      />

      <dialog-intervalo-meta-cumulativa
      ref="dialogIntervaloMetaCumulativa">
    </dialog-intervalo-meta-cumulativa>

    <dialog-verba-intervalo-meta-cumulativa
      ref="dialogVerbaIntervaloMetaCumulativa"
      :subtitulo="subTitulo">
    </dialog-verba-intervalo-meta-cumulativa>

  </div>
</template>

<script>

import ApuracaoContratoTabData from './ApuracaoContratoTabData';

export default {
  name: 'ApuracaoContratoTabSemestral',
  mixins: [
    ApuracaoContratoTabData,
  ],
  components: {
  },
  props: {
    apuracoes: {
      type: Array,
      default: () => ([]),
    },
    totalPage: {
      type: Number,
      default: 0,
    },
    verificaRetornaDatasValidas: {
      type: Function,
    },
    montarDataInicioFimEdicaoValores: {
      type: Function,
    },
    listaTiposUso: {
      type: Array,
      default: () => ([]),
    },
    filtros: {
      type: Object,
    },
    selecionarItem: Function,
  },
  data() {
    return {
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      lastPagination: {},
    };
  },
  computed: {
  },
  watch: {
    pagination: {
      handler(pagination) {
        if (this.lastPagination.page === null || typeof this.lastPagination.page === 'undefined') {
          this.lastPagination = pagination;
          return;
        }
        if (this.mesmaPagina(pagination, this.lastPagination) && !this.ordenacaoAlterada(pagination, this.lastPagination)) {
          return;
        }
        this.resetarExpanded();
        this.lastPagination = pagination;
        this.$emit('ApuracaoContratoTabSemestral__AplicaFiltros', this.pagination);
      },
      deep: true,
    },
    expanded(apuracao) {
      this.verificarApuracaoExpandida(apuracao);
    },
  },
  methods: {
  },
};
</script>
